import {
  DashedIconCircle,
  PhoneIcon,
  ProductDesignIcon,
  ServicesCircle,
  CloudMigrationIcon,
  IdeationIcon,
  CyberSecurityIcon,
  ArtificialIntelligenceIcon,
  MobileAppDevIcon,
  WebDevelopmentIcon,
} from "@Components/common/Icons";
import React, { useState } from "react";
import text from "@Lib/texts/home";

const icons = [
  {
    src: <WebDevelopmentIcon className="services-middle-icon" />,
  },
  {
    src: <MobileAppDevIcon className="services-middle-icon" />,
  },
  {
    src: <ProductDesignIcon className="services-middle-icon" />,
  },
  {
    src: <IdeationIcon className="services-middle-icon" />,
  },
  {
    src: <CloudMigrationIcon className="services-middle-icon" />,
  },
  {
    src: <CyberSecurityIcon className="services-middle-icon" />,
  },
  {
    src: <ArtificialIntelligenceIcon className="services-middle-icon" />,
  },
];
const ServicesCarousel = () => {
  const text = {
    services: [
      {
        title: "Web Development",
        subtitle:
          "Unlock growth with our tailor-made, industry-focused software solutions. Enhance operational efficiency, boost customer satisfaction, and gain a competitive edge in your digital journey.",
      },
      {
        title: "Mobile Development",
        subtitle:
          "Join forces with a leading name in mobile app development to sculpt your ideal mobile solution. We design and develop bespoke applications, laser-focused on engaging your target audience, enhancing user experience, and boosting your digital footprint.Harness our expertise in crafting both iOS and Android apps for unrivaled mobile excellence.",
      },
      {
        title: "Product Design",
        subtitle:
          "By weaving design thinking with the latest concepts from the UX/UI world, we craft immersive, user-centric products. Our offerings balance compelling aesthetics with robust functionality, promising a superior user experience that drives value in the digital ecosystem",
      },
      {
        title: "Post-Development Maintenance",
        subtitle:
          "We provide comprehensive maintenance and operational support for your existing solutions. Focus on growth while we ensure your systems run flawlessly. Experience worry-free operations with our expert team",
      },
      {
        title: "Cloud integration",
        subtitle:
          "Embrace Cloud Synergy: Effortless deployment and management await! We harness the power of industry-leading cloud providers like Azure, AWS, and GSP ans Cluster Power  to unlock your business's boundless potential. Unleash innovation today!",
      },
      {
        title: "Windows Development",
        subtitle:
          "Leverage the power of cross-platform development with our Windows Development service using Flutter. We specialize in creating robust, high-performance, and natively compiled applications for Windows from a single codebase. Our skilled developers harness the potential of Flutter's rich widget catalog and fast, beautiful UI to deliver top-notch desktop applications that drive user engagement and business growth.",
      },
      {
        title: "POC and Discovery Service",
        subtitle:
          "SAS Technologies proudly presents our Website POC and Discovery Service, designed to empower businesses and startups with a comprehensive analysis and optimization solution for their online presence. Through this service, we conduct a meticulous proof of concept (POC) and discovery process, unraveling valuable insights and delivering actionable recommendations to enhance your website's performance and user experience.",
      },
    ],
  };
  const [active, setActive] = useState(1);
  const [itemTitle, setItemTitle] = useState(text.services[0].title);

  return (
    <div className="services-carousel-container">
      <div className="services-carousel-text-container">
        {text.services.map((service, index) => (
          <div
            key={index}
            className={`services-carousel-banner ${
              active === index + 1 ? "services-carousel-banner-active" : ""
            }`}
          >
            <h4 className="font-black font-header text-2xl mb-4">
              {service.title}
            </h4>
            <p className="text-sm">{service.subtitle}</p>
          </div>
        ))}
      </div>

      <div className="services-carousel-right-section-container">
        <div key={active} className="services-carousel-item-text">
          {itemTitle}
        </div>
        <div
          className={`services-carousel-big-circle-container services-carousel-big-circle-container-active${active}`}
        >
          <ServicesCircle size={315} />
          <svg width="0" height="0">
            <defs>
              <clipPath id="myClip">
                <circle cx="225.8" cy="15.6" r="45" />
                <circle cx="89.2" cy="15.6" r="45" />
                <circle cx="301.55" cy="124.47" r="45" />
                <circle cx="4.45" cy="124.47" r="45" />
                <circle cx="157.5" cy="315" r="45" />
                <circle cx="280.65" cy="255.7" r="45" />
                <circle cx="34.35" cy="255.7" r="45" />
              </clipPath>
            </defs>
          </svg>

          {text.services.map((service, index) => (
            <div
              key={index}
              className={`services-carousel-item-container-${index + 1}`}
            >
              <ServicesCarouselItem
                title={service.title}
                position="1"
                icon={icons[index].src}
                setActive={() => {
                  setActive(index + 1);
                  setItemTitle(service.title);
                }}
                active={active === index + 1}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ServicesCarouselItem = ({
  position = "left",
  icon = <PhoneIcon className="services-middle-icon" />,
  setActive,
  active,
}) => {
  return (
    <div
      className={`services-carousel-item services-carousel-item${
        "-" + position
      }`}
    >
      <div
        className={`services-icon-container ${
          active ? "services-icon-container-active" : ""
        }`}
        onClick={() => setActive()}
      >
        <DashedIconCircle className="services-dashed-icon-circle" />
        {icon}
      </div>
    </div>
  );
};
export default ServicesCarousel;
