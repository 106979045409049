import React, { useState } from "react";
import Link from "next/link";
import Slider from "react-slick";
import CubeScene from "@Components/CubeScene";
import { CubesCarouselControll } from "@Components/CubesCarousel";
import ProjectCard from "@Components/ProjectCard";
import ServicesCarousel from "@Components/ServicesCarousel";
import Testimonial from "@Components/Testimonial";
import Button from "@Components/common/ButtonCustom";
import parse from "html-react-parser";
import { useWindowDimensions } from "@Services/contexts/WindowDimensionsContext";
import projects from "./project-details/text-data.json";

const Home = () => {
  const text = {
    heroSectionToProjectSection:
      "Your Collaborative Ally Through Each Stage of Product Development. Optimize your  <span class='magenta'>product journey with a trusted partner adept at guiding</span> you from concept to market.",
    projectsSection: {
      title:
        "More than 5 years in the game and we're <span class='magenta'>just getting started.</span>",
      subtitle:
        "From product design to software continuous delivery, we develop custom IT solutions for all industries.Check our latest projects.",
      button: "See all projects",
    },
    servicesSection: {
      title:
        "Masters of Every Phase in the <span class='magenta'>Product Lifecycle</span>",
      subtitle:
        "Regardless of your company's size or stage, we equip our clients with the creative, technical, and business expertise necessary for success.",
    },
    reviewsSection: {
      title: "Clients <span class='magenta'>trust us</span>",
      subtitle:
        "A trust-worthy relationship is built slowly through constant actions, we never let our clients down, you can ask them yourself.",
      testimonials: [
        {
          name: "Marcel Foca, CityZen.ro",
          review:
            "SAS Technologies team is the ideal partner for projects, their approach combines the pragmatism of the results with the flexibility of the road to the agreed objectives. They are not just another outsourcer, rather your project partner. So talk to them about goals and intermediate deliverables, less about man-day. We have worked and will continue to work with them.",
        },
        {
          name: "DERS",
          review:
            "We are delighted to express our utmost satisfaction with the collaboration involving the development of our DERS platform application. SAS Technologies and their development team have delivered an exceptionally professional job. The solution provided to us has exceeded our expectations, and we are highly pleased with the seamless communication throughout the project. We wholeheartedly recommend SAS Technologies and their proficient development team. Their expertise and dedication have left a lasting impression, and we eagerly anticipate the opportunity to collaborate with them again in future projects.",
        },
        {
          name: "Arctic Stream",
          review:
            "One of the most remarkable aspects of SAS Technologies is their unwavering commitment to delivering results. The solutions they provided not only met but exceeded my expectations, showcasing their dedication to their clients. Their attention to detail, coupled with their creative problem-solving, left me thoroughly impressed. I recommend SAS Technologies to anyone seeking top-notch technological solutions coupled with exceptional customer service.",
        },
      ],
    },
    blogSection: {
      title: "Latest <span class='magenta'>blog posts</span>",
      subtitle:
        "We are all enthusiasts of our field, real geeks for new technologies, projects and everything it has to offer. We know we are not alone, here you can find the latest news in the industry.",
      button: "See all articles",
    },
    blogSectionToPageEnd:
      "Share your concept with us and our team will support you on a journey to deliver a <span class='magenta'>revolutionary digital product.</span>",
  };

  const { isMobile } = useWindowDimensions();
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 123123000,
    speed: 800,
    slidesToShow: 1,
    SlidesToScroll: 1,
    centerMode: true,
    centerPadding: "25px",
    arrows: false,
    swipe: true,
  };
  const [activeIndex, setActiveIndex] = useState(1);
  const carouselSize = 3;
  const projectsItem = projects.slice(0, 3);
  return (
    <>
      <section style={{ overflow: "hidden" }} className="mb-20">
        <div className="inner-section">
          <div className="cube-container">
            <CubeScene activeIndex={activeIndex} />
            <CubesCarouselControll
              carouselSize={carouselSize}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          </div>
        </div>
      </section>

      <section className="inner-section projects-section">
        <h2 className="text-2xl sm:text-5xl font-header font-black text-center">
          {parse(text.projectsSection.title)}
        </h2>
        <p className="text-center mx-auto mb-6 py-20 max-w-3xl">
          {text.projectsSection.subtitle}
        </p>

        <div className="w-full h-fit justify-between">
          <div className="h-full">
            {isMobile ? (
              <Slider {...settings}>
                {projects.slice(0, 3).map((project, index) => {
                  const id = project?.id
                  const content = project?.content

                  return (
                    <div
                      className="sm:w-[70%] md:max-w-[390px] basis-full"
                      key={index}
                    >
                      <ProjectCard
                        id={id}con
                        project={content}
                        className="mx-5"
                      />
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="w-full flex justify-center gap-4 flex-wrap">
              
                {projects.slice(0, 3).map((project) => {
                  const id = project?.id
                  const content = project?.content
                  return (
                    <div className="sm:w-[70%] md:max-w-[390px] basis-full">
                    <ProjectCard
                      id={id}
                      project={content}
                      className=""
                    />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="projects-section-see-all mb-40">
          <Link href="/project-details" passHref={true}>
            <Button className="btn-margin" type="primary">
              {text.projectsSection.button}
            </Button>
          </Link>
        </div>
      </section>

      <section className="inner-section our-services-container">
        <div className="flex flex-col gap-4 mb-10">
          <h3 className="text-2xl sm:text-3xl font-black font-header max-w-[24ch]">
            Masters of Every Phase in the{" "}
            <span className="text-primary">Product Lifecycle</span>
          </h3>

          <p className="max-w-[60ch]">{text.servicesSection.subtitle}</p>
        </div>

        <ServicesCarousel />
      </section>

      <section className="inner-section testimonials-section">
        <div className="testimonials-section-container">
          {isMobile ? (
            <div className="h-full">
              <Slider {...settings}>
                {text.reviewsSection.testimonials.map((testim, index) => (
                  <Testimonial
                    key={index}
                    author={testim.name}
                    text={testim.review}
                  />
                ))}
              </Slider>
            </div>
          ) : (
            <div className="flex flex-col gap-10 basis-full">
              {text.reviewsSection.testimonials.map((testim, index) => (
                <Testimonial
                  key={index}
                  author={testim.name}
                  text={testim.review}
                />
              ))}
            </div>
          )}

          <div className="testimonials-section-container-col">
            <div className="testimonials-section-container-col-sticky">
              <h3 className="font-black font-header text-3xl mb-4">
                {parse(text.reviewsSection.title)}
              </h3>
              <p className="mb-10">{text.reviewsSection.subtitle}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-section">
        <div className="pt-10 pb-24 sm:py-60 font-semibold">
          <h3 className="font-header text-2xl sm:text-4xl font-black text-center">
            {parse(text.blogSectionToPageEnd)}
          </h3>
        </div>
      </section>
    </>
  );
};

export default Home;
