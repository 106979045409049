import React from "react";

const Cube = ({
  containerClass,
  heightType = "small",
  active = true,
  icon,
}) => {
  return (
    <div
      className={`cube ${containerClass} ${!active && "urca1"} ${
        icon ? `cube-active-icon-` + containerClass : ""
      }`}
      // style={icon ? { transform: "translateZ(120px)" } : null}
    >
      <div className={`cube-face cube-face-front ${active && "opc"}`}>
        {/* front */}
      </div>

      <div
        className={`cube-side cube-face-right ${
          active && "opc"
        } cube-side-right-${heightType}`}
      >
        {/* right */}
      </div>

      <div
        className={`
          cube-side cube-face-bottom ${
            active && "opc"
          } cube-side-left-${heightType}`}
      >
        {/* bottom */}
      </div>
      {icon ? (
        <>
          <div className="cube-icon">{icon}</div>{" "}
          <div className="cube-icon-shadow" />
        </>
      ) : null}
    </div>
  );
};
export default Cube;
