import React, { useEffect, useState } from "react";
import Cube from "@Components/common/Cube";
import {
  ReactIcon,
  VueIcon,
  SpringIcon,
  SwiftIcon,
  KotlinIcon,
  AiIcon,
  BlenderIcon,
  FigmaIcon,
  FlutterIcon,
  XdIcon,
} from "./common/Icons";
const noCubes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
const CubeScene = ({ activeIndex }) => {
  const [actives, setActives] = useState(false);
  useEffect(() => {
    setActives(true);
  }, []);
  const handleHeightType = (val) => {
    if (
      val === 1 ||
      val === 4 ||
      val === 5 ||
      val === 7 ||
      val === 8 ||
      val === 10 ||
      val === 11 ||
      val === 13
    ) {
      return "small";
    }
    if (val === 2 || val === 3 || val === 9 || val === 12) {
      return "medium";
    }
    return "large";
  };
  const handleIconType = (val, activeIndex) => {
    if (activeIndex === 1) {
      if (val === 2) return <KotlinIcon size={70} />;
      if (val === 6) return <SpringIcon size={70} />;
      if (val === 14) return <VueIcon size={70} />;
      if (val === 9) return <ReactIcon size={70} />;
    }
    if (activeIndex === 2) {
      if (val === 13) return <ReactIcon size={70} />;
      if (val === 11) return <FlutterIcon size={70} />;
      if (val === 3) return <SwiftIcon size={70} />;
      if (val === 5) return <KotlinIcon size={70} />;
      if (val === 7) return <SpringIcon size={70} />;
    }
    if (activeIndex === 3) {
      if (val === 10) return <FigmaIcon size={70} />;
      if (val === 8) return <XdIcon size={70} />;
      if (val === 1) return <BlenderIcon size={70} />;
      if (val === 12) return <AiIcon size={70} />;
    }
  };

  return (
    <div className="scene">
      {noCubes.map((val, idx) => (
        <Cube
          containerClass={`cub${val}`}
          heightType={handleHeightType(val)}
          active={actives}
          icon={handleIconType(val, activeIndex)}
          key={idx}
        />
      ))}
    </div>
  );
};

export default CubeScene;
