import React, { useEffect, useState } from "react";
import { ArrowRight, ArrowLeft } from "@Components/common/Icons";
import Typewriter from "typewriter-effect";
import Button from "./common/ButtonCustom";
import { useRouter } from "next/router";
import Link from "next/link";

const CubesCarouselControll = ({
  activeIndex,
  setActiveIndex,
  carouselSize,
}) => {
  const changingTime = 10;
  const [timer, setTimer] = useState(changingTime);
  const handleIncrease = () => {
    setTimer(changingTime);
    setActiveIndex((prevState) => {
      if (prevState === carouselSize) {
        return 1;
      } else {
        return prevState + 1;
      }
    });
  };
  const handleDecrease = () => {
    setTimer(changingTime);
    setActiveIndex((prevState) => {
      if (prevState === 1) {
        return carouselSize;
      } else {
        return prevState - 1;
      }
    });
  };
  useEffect(() => {
    if (timer == 0) {
      handleIncrease();
    }
    // eslint-disable-next-line
  }, [timer]);
  useEffect(() => {
    const carouselInterval = setInterval(
      () => setTimer((prevState) => prevState - 1),
      1000
    );

    return () => clearInterval(carouselInterval);
  }, []);

  return (
    <div className="cubes-carousel-container">
      <div className="cubes-carousel-controll">
        <div className="arrow-container" onClick={() => handleDecrease()}>
          <ArrowLeft />
        </div>
        <div className="cubes-carousel-current-slide-container">
          <div>0{activeIndex}</div>
          <div>/</div>
          <div>0{carouselSize}</div>
        </div>
        <div className="arrow-container" onClick={() => handleIncrease()}>
          <ArrowRight />
        </div>
      </div>
      <CubesCarouselContent activeIndex={activeIndex} />
    </div>
  );
};

const CubesCarouselContent = ({ activeIndex }) => {
  const text = {
    heroSection: {
      title: "Custom",
      services: [
        {
          title: "Web Development",
          subtitle:
            "Propel your growth with our industry-tailored software solutions. Optimize operations, enhance customer delight, and secure your digital edge.",
        },
        {
          title: "Mobile Development",
          subtitle:
            "Partner with us to craft your perfect mobile solution. Our custom apps engage audiences and amplify your digital reach. Experience unrivaled mobile excellence in iOS and Android platforms.",
        },
        {
          title: "Product Design",
          subtitle:
            "Experience the harmony of design and technology in our user-centric products. We blend striking aesthetics with robust functionality for superior digital experiences.",
        },
      ],
      button: "See our projects",
    },
  };

  const router = useRouter();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
  }, [activeIndex]);

  return (
    <div className="cubes-carousel-text-wrapper">
      <div className={`cubes-carousel-text-container${"-active"}`}>
        <div className="cubes-carousel-title">
          <h3 className="cubes-carousel-text-title font-header">
            {text.heroSection.title} <br />
            <span className="magenta typewriter-home" key={activeIndex}>
              <Typewriter
                options={{
                  delay: 50,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(
                      text.heroSection.services[activeIndex - 1].title
                    )
                    .callFunction(() => setShow(true))
                    .start();
                }}
              />
            </span>
          </h3>
        </div>
        <div
          className={`cubes-carousel-text cubes-carousel-text-${
            show ? "active" : ""
          }`}
          key={activeIndex}
        >
          <div className="text-black">
            {text.heroSection.services[activeIndex - 1].subtitle}
          </div>

          <Link href={"/project-details"}>
            <Button type="primary" className="btn-margin">
              {text.heroSection.button}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export { CubesCarouselControll, CubesCarouselContent };
