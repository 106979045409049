const Testimonial = ({ author = "", text = "" }) => {
  return (
    <div className="px-2 items-stretch h-full">
      <div className="border-dashed border-2 h-full border-gray-600 p-4 md:p-8 text-sm md:text-base rounded-xl flex flex-col gap-10">
        <p className="italic">{text}</p>
        <h5 className="font-black">{author}</h5>
      </div>
    </div>
  );
};
export default Testimonial;
